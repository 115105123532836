import styled from "styled-components";

const regex = /Question: (.*)/g;

const Header = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: black;
  justify-content: center;
`;

const T1 = styled.h1`
  font-weight: bold;
  font-family: "Raleway";
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const T2 = styled.h2`
  font-weight: bold;
  font-family: "Raleway";
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const T3 = styled.h3`
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Raleway";
`;

const T4 = styled.h4`
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Raleway";
`;

const P = styled.p`
  line-height: 1.7;
  margin-bottom: 10px;
  font-size: 14px;
`;
const LI = styled.li`
  margin-bottom: 10px;
  padding-left: 10px;
  line-height: 1.7;
  font-size: 14px;
`;

const OL = styled.ol`
  padding-left: 0px;
  font-size: 14px;
  line-height: 1.7;
  margin-bottom: 5px;
`;

export function formatElements(rawString) {
  if (!rawString) return null;

  let elements = [];
  let lines = rawString.split("\n");
  let i = 0;

  while (i < lines.length) {
    let line = lines[i];
    if (!line) {
      i++;
      continue;
    }

    // Handle headers
    if (line.startsWith("# ")) {
      elements.push(<T1 key={i}>{line.replace(/^#\s*/, "").trim()}</T1>);
    } else if (line.startsWith("## ")) {
      elements.push(<T2 key={i}>{line.replace(/^##\s*/, "").trim()}</T2>);
    } else if (line.startsWith("### ")) {
      elements.push(<T3 key={i}>{line.replace(/^###\s*/, "").trim()}</T3>);
    } else if (line.startsWith("#### ")) {
      elements.push(<T4 key={i}>{line.replace(/^####\s*/, "").trim()}</T4>);
    } else {
      // Handling ordered list items
      if (line.trim().match(/^\d+\.\s/)) {
        elements.push(
          <OL
            key={i}
            dangerouslySetInnerHTML={{
              __html: line
                .replace(/\*\*(.*?)\*\*/g, (match, p1) => `<b>${p1}</b>`)
                .trim(),
            }}
          ></OL>
        );
      }
      // Handling unordered list items
      else if (line.trim().startsWith("-")) {
        elements.push(
          <LI
            key={i}
            dangerouslySetInnerHTML={{
              __html: line
                .replace(/^\s*-\s*/, "")
                .replace(/\*\*(.*?)\*\*/g, (match, p1) => `<b>${p1.trim()}</b>`)
                .trim(),
            }}
          ></LI>
        );
      }
      // Handling bold text within lines
      else {
        elements.push(
          <P
            key={i}
            dangerouslySetInnerHTML={{
              __html: line
                .replace(/\*\*(.*?)\*\*/g, (match, p1) => `<b>${p1}</b>`)
                .trim(),
            }}
          ></P>
        );
      }
    }

    i++;
  }

  return elements;
}

const parseBoldText = (text) => {
  const regex2 = /\*\*(.*?)\*\*/g;
  let parts = [];
  let lastIndex = 0;
  let match;

  while ((match = regex2.exec(text)) !== null) {
    // Add text before the bold syntax
    if (match.index > lastIndex) {
      parts.push({
        text: text.substring(lastIndex, match.index),
        bold: false,
      });
    }
    // Add the bold text
    parts.push({ text: match[1], bold: true });
    lastIndex = match.index + match[0].length;
  }

  // Add any remaining text after the last bold syntax
  if (lastIndex < text.length) {
    parts.push({ text: text.substring(lastIndex), bold: false });
  }

  return parts;
};

function parseArrayString(input) {
  const results = [];
  let buffer = "";
  let insideString = false;
  let currentQuote = null;
  for (let i = 0; i < input.length; i++) {
    const char = input[i];

    if ((char === "'" || char === '"') && !insideString) {
      // Start of a new entry
      insideString = true;
      currentQuote = char;
      buffer = "";
    } else if (char === currentQuote && insideString) {
      // End of the current entry
      insideString = false;
      results.push(buffer);
      buffer = "";
    } else if (insideString) {
      // Inside an entry
      buffer += char;
    }
  }

  return results;
}
