import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";

const TemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px; /* Spacing between child elements */
  height: ${({ scrollable }) =>
    scrollable ? "100%" : "auto"}; /* Full height for scrolling */
  min-height: 80vh;
  overflow-y: ${({ scrollable }) =>
    scrollable ? "auto" : "hidden"}; /* Scrollable or not */
  overflow-x: hidden;

  /* Custom Scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.neutral[200]};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.neutral[500]};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.neutral[600]};
  }
`;

// Wrapper for the title and subtitle
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px; /* Space between title and subtitle */
  width: 1086px;
  height: auto; /* Allow height to adjust based on content */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

// Title styles (e.g., "Pulse AI Surveys")
const Title = styled.h1`
  font-family: ${typography.fontFamily};
  font-weight: ${typography.display.bold.fontWeight};
  font-size: ${typography.display.bold.fontSize};
  line-height: ${typography.display.bold.lineHeight};
  color: ${colors.text.dark}; /* Use dark text color from colors.js */
  margin: 0; /* Reset default margin */
`;

// Subtitle styles (e.g., "AI Toolkit")
const Heading = styled.span`
  font-family: ${typography.fontFamily};
  font-weight: ${typography.caption.semibold.fontWeight};
  font-size: ${typography.caption.semibold.fontSize};
  line-height: ${typography.caption.semibold.lineHeight};
  font-feature-settings: ${typography.fontFeatureSettings};
  color: ${colors.primary[500]}; /* Use primary color from colors.js */
  margin: 0; /* Reset default margin */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

const BaseTemplate = ({
  children,
  heading,
  pageTitle,
  subtitle,
  scrollable,
  pagination,
}) => {
  return (
    <TemplateWrapper scrollable={scrollable}>
      {heading && <Heading>{heading}</Heading>}
      {pageTitle && (
        <TitleWrapper>
          <Title>{pageTitle}</Title>
        </TitleWrapper>
      )}
      {children}
      {pagination}
    </TemplateWrapper>
  );
};

BaseTemplate.propTypes = {
  children: PropTypes.node, // Content passed into the template
  heading: PropTypes.string, // Heading text
  pageTitle: PropTypes.string, // Page title
  subtitle: PropTypes.string, // Page subtitle
  scrollable: PropTypes.bool, // Whether the template should handle scrolling
};

BaseTemplate.defaultProps = {
  scrollable: true, // Default to a scrollable template
};

export default BaseTemplate;