// FeedbackContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_TASK_SCHEDULES,
  SG_GET_TASK_TEMPLATES,
  SG_GET_TASK_TEMPLATE_SELECT,
} from "constants/actions";

/**
 * Parses a pulse survey object (like the one provided) and returns a
 * structured feedback string.
 *
 * @param {Object} pulseSurvey - The entire pulse survey object.
 * @returns {String} A formatted string of user feedback.
 */
function parsePulseSurveyResponses(pulseSurvey) {
  // Safety check: if no task_responses, return early.
  if (!pulseSurvey?.task_responses || pulseSurvey.task_responses.length === 0) {
    return "No task responses found.";
  }

  let feedbackString = "";

  pulseSurvey.task_responses.forEach((responseObj) => {
    // Each responseObj corresponds to one user (or one response session).
    // We can pull out 'employee' or 'email' if we want to note it in our feedback as well.
    const { task_response, employee } = responseObj;
    const userEmail = task_response?.email;

    // If you want to identify which user’s feedback you are reporting:
    //   feedbackString += `=== Feedback from user ID ${employee} (${userEmail}) ===\n\n`;

    // The main array of question-response pairs is in task_list
    const taskList = task_response?.task_list;
    if (!taskList || taskList.length === 0) {
      feedbackString += "No questions answered.\n\n";
      return; // proceed to next responseObj
    }

    // Loop each question/response in the task_list
    taskList.forEach((item) => {
      const { question, response, follow_up } = item;

      if (typeof response === "number") {
        // This is presumably a "quantitative" question
        feedbackString += `User was asked quantitative question: ${question}\n\n`;
        feedbackString += `User scored this question: ${response}\n\n`;

        // If there’s a follow-up for the quantitative question
        if (follow_up && follow_up.question && follow_up.response) {
          feedbackString += `User was asked follow-up question: ${follow_up.question}\n`;
          feedbackString += `User responded to the follow-up question with: ${follow_up.response}\n\n`;
        }
      } else {
        // This is presumably an "open text" question
        feedbackString += `User provided feedback for the following question: ${question}\n\n`;
        feedbackString += `User responded with: ${response}\n\n`;

        // If there’s a follow-up for the open-text question
        if (follow_up && follow_up.question && follow_up.response) {
          feedbackString += `User was asked follow-up question: ${follow_up.question}\n`;
          feedbackString += `User responded to the follow-up question with: ${follow_up.response}\n\n`;
        }
      }
    });

    //   feedbackString += `=== End of feedback from user ID ${employee} ===\n\n`;
  });

  return feedbackString.trim();
}

// Helper function to calculate factor average
const calculateFactorAverage = (questions) => {
  let total = 0;
  let count = 0;

  questions.forEach((question) => {
    let response = question.response;
    if (question.reverse) {
      // Invert the score if the reverse is true
      response = 10 - response;
    }
    total += response;
    count++;
  });

  // Avoid division by zero
  const average = count > 0 ? (total / count) * 10 : 0;
  return average.toFixed(2); // Return percentage formatted to two decimal places
};

export const FeedbackContext = createContext();

export const useFeedbackContext = () => useContext(FeedbackContext);

export const FeedbackProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState("");
  const [feedbackLength, setFeedbackLength] = useState(0);
  const [feedbackLoading, setFeedbackLoading] = useState(true);
  const [emptyData, setEmptyData] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [selectedPulse, setSelectedPulse] = useState(null);
  const [pulseData, setPulseData] = useState(null);
  const [selectedFactors, setSelectedFactors] = useState([]);
  const [selectedOutcomes, setSelectedOutcomes] = useState([]);
  const [factorCardData, setFactorCardData] = useState([]);
  const [selectedPulses, setSelectedPulses] = useState([]);
  const [outcomeCardData, setOutcomeCardData] = useState([]);
  const filteredData = useSelector((state) => state.audit?.filtered_data);
  const coreData = useSelector((state) => state.audit?.core_data);
  const questionStructure = useSelector(
    (state) => state.audit?.survey_version,
    shallowEqual
  );

  const fetchedPulse = useSelector(
    (state) => state.task_templates?.fetched_scheduled_task
  );

  const pulseSurveys = useSelector(
    (state) => state.task_templates.scheduled_tasks?.results
  );

  useEffect(() => {
    dispatch({ type: SG_GET_TASK_TEMPLATE_SELECT });
    dispatch({ type: SG_GET_TASK_SCHEDULES });
    dispatch({ type: SG_GET_TASK_TEMPLATES });
  }, [dispatch]);

  // useEffect(() => {
  //   // When a pulse is selected, we need to fetch that pulse's data
  //   if (selectedPulse) {
  //     dispatch({
  //       type: SG_FETCH_TASK_SCHEDULE,
  //       payload: selectedPulse,
  //     });
  //   }
  // }, [selectedPulse, dispatch]);

  // useEffect(() => {
  //   if (fetchedPulse) {
  //     const pulseData = parsePulseSurveyResponses(fetchedPulse);
  //     setPulseData(pulseData);
  //   }
  // }, [fetchedPulse]);

  const factorLabels = React.useMemo(() => {
    if (!questionStructure) return [];

    let idCounter = 1;

    // For each dimension, build a structure that includes
    // dimension info plus an array of its factors.
    return (
      questionStructure.questions.dimensions?.map((dim, dimensionIndex) => {
        return {
          dimensionIndex,
          dimensionTitle: dim.title, // only if you want to include the dimension’s title
          factors: dim.factors?.map((factor, factorIndex) => ({
            title: factor.title,
            level: 2,
            id: idCounter++,
            dimension: dimensionIndex,
            factor: factorIndex,
          })),
        };
      }) || []
    ); // fallback to empty array if no dimensions
  }, [questionStructure]);

  useEffect(() => {
    // Add the type: 'factor' to factorCardData
    setFactorCardData(
      selectedFactors?.map((factor) => ({
        ...factor,
        type: "factor",
      }))
    );
  }, [selectedFactors]);

  const handleRemoveFactor = (factor) => {
    // Remove the factor from the selectedFactors array.
    // The factor object should be removed by matching factor.id, factor.dimension, and factor.factor.
    setSelectedFactors(
      selectedFactors.filter(
        (f) =>
          f.id !== factor.id ||
          f.dimension !== factor.dimension ||
          f.factor !== factor.factor
      )
    );
  };

  const handleRemoveOutcome = (outcome) => {
    setSelectedOutcomes(selectedOutcomes.filter((o) => o.id !== outcome.id));
  };

  useEffect(() => {
    // Add the type: 'outcome' to outcomeCardData
    setOutcomeCardData(
      selectedOutcomes?.map((outcome) => ({
        ...outcome,
        type: "outcome",
      }))
    );
  }, [selectedOutcomes]);

  const generateDefaultGroupStructure = (
    outcomeQ,
    isFrench = false,
    pillarOnly = false
  ) => {
    if (!questionStructure) return [];

    let idCounter = 0;
    const defaultGroupStructure = [];

    // Overall Culture
    if (!pillarOnly) {
      defaultGroupStructure.push({
        title: isFrench ? "Culture Générale" : "Overall Culture",
        level: 0,
        id: -1,
      });
    }

    // Dimensions & Factors
    questionStructure.questions.dimensions?.forEach((dim, dimensionIndex) => {
      if (!pillarOnly) {
        defaultGroupStructure.push({
          title: isFrench ? dim.fr || dim.title : dim.title,
          level: 1,
          id: idCounter++,
          dimension: dimensionIndex,
        });
      }

      dim.factors?.forEach((factor, factorIndex) => {
        defaultGroupStructure.push({
          title: isFrench ? factor.fr || factor.title : factor.title,
          level: 2,
          id: idCounter++,
          dimension: dimensionIndex,
          factor: factorIndex,
        });
      });
    });

    // Outcomes
    if (outcomeQ?.questions?.length > 0) {
      defaultGroupStructure.push({
        title: "Outcomes",
        level: 1,
      });
      outcomeQ.questions.forEach((oq) => {
        defaultGroupStructure.push({
          title: oq.name,
          id: oq.id,
          level: 3,
          type: "outcome",
        });
      });
    }

    // Custom Questions
    if (questionStructure?.questions?.comments?.length > 0) {
      defaultGroupStructure.push({
        title: "Custom Questions",
        level: 1,
      });
      questionStructure.questions.comments.forEach((c, i) => {
        defaultGroupStructure.push({
          title: c.question,
          id: i,
          level: 4,
          type: "comment",
        });
      });
    }

    return defaultGroupStructure;
  };

  // Helper to determine the group list
  const getGroupList = (
    selectedGroup,
    defaultGroupStructure,
    groupStructure,
    isOTP
  ) => {
    if (
      selectedGroup.length > 0 &&
      selectedGroup[0]?.title !== "Overall Culture" &&
      selectedGroup[0]?.id !== -1
    ) {
      return selectedGroup;
    }
    return isOTP ? defaultGroupStructure : [];
  };

  const handleSelectPulse = (pulseId) => {
    // If the pulse is already selected, return;
    // If the pulse is not selected, add it to the selectedPulses array.
    // The object should have the { id, type } of the pulse.
    if (selectedPulses.some((p) => p.id === pulseId)) return;
    // Find the pulse so we can get the title.
    const pulse = pulseSurveys.find((p) => p.id === pulseId);
    setSelectedPulses([
      ...selectedPulses,
      { id: pulseId, type: "pulse", title: pulse?.name || "N/A" },
    ]);
  };

  const handleRemovePulse = (pulseId) => {
    // Remove the pulse from the selectedPulses array.
    setSelectedPulses(selectedPulses.filter((p) => p.id !== pulseId));
  };

  // Helper to filter feedback and questions by group level
  const filterByGroupLevel = (group, feedback, questions) => {
    let filteredFeedback = [...feedback];
    let filteredQuestions = [...questions];

    switch (group.level) {
      case 1:
        filteredFeedback = filteredFeedback.filter(
          (f) =>
            f.feedback.id === group.dimension ||
            f.feedback?.dimension === group.dimension
        );
        filteredQuestions = filteredQuestions.filter((q) =>
          q.questions.some((question) => question.id === group.dimension)
        );
        break;
      case 2:
        filteredFeedback = filteredFeedback.filter(
          (f) =>
            (f.feedback.id === group.dimension ||
              f.feedback?.dimension === group.dimension) &&
            f.feedback.factor === group.factor
        );
        filteredQuestions = filteredQuestions.map((q) => ({
          ...q,
          questions: q.questions.filter(
            (question) =>
              question.id === group.dimension &&
              question.factor === group.factor
          ),
        }));
        break;
      case 3:
        filteredFeedback = filteredFeedback.filter(
          (f) =>
            f.type === "outcome" && Number(f.feedback.q) === Number(group.id)
        );
        filteredQuestions = filteredQuestions.filter((q) =>
          q.questions.some(
            (question) => Number(question.q) === Number(group.id)
          )
        );
        break;
      case 4:
        filteredFeedback = filteredFeedback.filter(
          (f) => f.type === "comments" && f.id === group.id
        );
        filteredQuestions = filteredQuestions.filter((q) =>
          q.questions.some((question) => question.q === group.id)
        );
        break;
      default:
        break;
    }

    return { filteredFeedback, filteredQuestions };
  };

  // Helper to generate feedback text
  const generateFeedbackText = (groupFeedback, isOTP, coreData) => {
    let feedbackText = "";

    groupFeedback.forEach((item) => {
      if (item.type === "comments") {
        feedbackText += `Question: ${
          isOTP
            ? item.feedback.question
            : coreData.questions?.comments?.[item.id]?.question || "N/A"
        }\n`;
        feedbackText += `User feedback: ${
          isOTP ? item.feedback.response : item.feedback.feedback
        }\n`;
      } else if ("prompt_feedback" in item.feedback) {
        feedbackText += `Follow-up question: ${item.feedback.prompt_question}\n`;
        feedbackText += `User feedback: ${item.feedback.prompt_feedback}\n`;
      } else if (
        "feedback" in item.feedback &&
        item.feedback.feedback?.length
      ) {
        feedbackText += `User feedback: ${item.feedback.feedback}\n`;
      }

      if (item.feedback.follow_up) {
        feedbackText += `Follow-up: ${item.feedback.follow_up}\n`;
      }
      if (item.feedback.response && item.type === "outcome") {
        feedbackText += `Outcome response: ${item.feedback.response}\n`;
      }

      feedbackText += "\n";
    });

    return feedbackText;
  };

  // Main function
  const appendFeedback = (
    originalFeedbackData = [],
    originalQuestionData = [],
    metaTag = "",
    options = {}
  ) => {
    const { selectedGroup = [], groupStructure = [], isOTP = false } = options;

    let allFilteredQuestions = [];
    let allFilteredFeedback = [];
    let feed = "";

    // Combine the selectedFactors and selectedOutcomes (if any)
    const selectedAnalysis = [
      ...selectedFactors,
      ...selectedOutcomes.map((outcome) => ({
        title: outcome.name,
        id: outcome.id,
        level: 3,
        type: "outcome",
      })),
    ];

    const defaultGroupStructure =
      selectedAnalysis?.length > 0
        ? selectedAnalysis
        : generateDefaultGroupStructure(coreData?.outcomeQuestions);

    const groupList = getGroupList(
      selectedFactors,
      defaultGroupStructure,
      groupStructure,
      isOTP
    );

    groupList.forEach((group) => {
      const { filteredFeedback, filteredQuestions } = filterByGroupLevel(
        group,
        originalFeedbackData,
        originalQuestionData,
        isOTP,
        coreData
      );

      // Calculate factor average
      const factorAverage = calculateFactorAverage(
        filteredQuestions.flatMap((q) => q.questions)
      );

      // Append group-level feedback
      feed += `### ${group.title} ${metaTag} - ${group.title} - scored - ${factorAverage}%:\n\n`;

      // Generate feedback text
      feed += generateFeedbackText(filteredFeedback, isOTP, coreData);

      feed += `### End of ${group.title} feedback\n\n`;

      allFilteredFeedback.push(...filteredFeedback);
      allFilteredQuestions.push(...filteredQuestions);
    });

    return feed;
  };

  const getQuestionResponses = () => {
    let _data = [];
    let filtered = filteredData?.[0]?.[0] ? filteredData[0] : filteredData;

    filtered
      ?.filter((f) => "questions" in f) // Check if 'questions' exists
      ?.filter((f) => f?.questions?.length > 0) // Ensure questions have data
      ?.map((item) => {
        // Push object with questions and categories
        _data.push({
          questions: item.questions, // Store questions
          categories: item.categories, // Include categories for context
        });
      });

    return _data;
  };

  const getFeedbackContext = () => {
    let _data = [];
    let filtered = filteredData?.[0]?.[0] ? filteredData[0] : filteredData;

    if (!initialLoad && filtered?.length > 0) setInitialLoad(true);
    if (filtered?.length === 0) setEmptyData(true);
    else setEmptyData(false);

    filtered
      ?.filter((item) => item.feedback?.length > 0)
      .forEach((item) =>
        item.feedback.forEach((f) =>
          _data.push({ feedback: f, categories: item.categories })
        )
      );

    return _data;
  };

  useEffect(() => {
    if (!filteredData || !coreData) return;
    const contextData = getFeedbackContext();
    const questionData = getQuestionResponses();
    setFeedbackLength(contextData.length);
    // Append feedback using the function
    const compiledFeedback = appendFeedback(contextData, questionData);
    setFeedback(compiledFeedback);

    setFeedbackLoading(false);
  }, [
    filteredData,
    coreData,
    questionStructure,
    selectedFactors,
    selectedOutcomes,
  ]);

  // ----------------------------------
  // Expose a param-builder for the Chat
  // ----------------------------------
  // If you'd like to add more fields (like "tags"), you can do so,
  // or pull them from another context if needed.
  const getFeedbackParams = (fileSources = []) => {
    const metadata =
      fileSources?.length > 0
        ? fileSources?.map((source) => ({
            id: source.id,
            type: "file",
            name: source.name,
          }))
        : [];

    const tags = selectedFactors?.map((factor) => factor.title);
    // Add outcomes to the tags
    selectedOutcomes?.forEach((outcome) => tags.push(outcome.name));
    const pulseData =
      selectedPulses?.map((pulse) => ({
        id: pulse.id,
        type: "pulse",
      })) || [];

    const dataSources = [...pulseData, ...metadata];

    return {
      feedback_length: feedbackLength,
      date: "",
      // This is an expected param, do not overwrite
      context_data_length: 0,
      // If you do store 'tags' or 'factors' here, you can add them:
      tags,
      dataSources,
    };
  };

  const handleOutcomeSelection = (outcomeId) => {
    const selectedOutcome = outcomeCardData.find((o) => o.id === outcomeId);
    if (selectedOutcome) {
      setOutcomeCardData(
        outcomeCardData.map((o) =>
          o.id === outcomeId ? { ...o, selected: !o.selected } : o
        )
      );
    }
  };

  return (
    <FeedbackContext.Provider
      value={{
        feedback,
        feedbackLength,
        feedbackLoading,
        emptyData,
        initialLoad,
        getFeedbackParams,
        fetchedPulse,
        pulseSurveys,
        setSelectedPulse,
        pulseData,
        outcomeQ: coreData?.outcomeQuestions,
        factorLabels,
        setSelectedFactors,
        setSelectedOutcomes,
        handleSelectPulse,
        handleRemovePulse,
        selectedPulses,
        selectedFactors,
        factorCardData,
        outcomeCardData,
        handleRemoveFactor,
        handleRemoveOutcome,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};
