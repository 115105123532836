import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "styles/colors";
import Icon from "new_components/atoms/Icon";
import typography, { applyTypography } from "styles/typography";

/** STYLES **/

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelWrapper = styled.label`
  ${applyTypography(typography.body.regular)}
  color: ${colors.text.dark};
  margin-bottom: 0.25rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.neutral[500]};
  border-radius: 0.375rem;
  background-color: ${colors.background.white};
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  &:focus-within {
    border-color: ${colors.primary[500]};
  }
  &.error {
    border-color: ${colors.auxiliary.red[500]};
  }
  &.disabled {
    background-color: ${colors.neutral[200]};
    color: ${colors.neutral[600]};
    cursor: not-allowed;
    textarea {
      background-color: ${colors.neutral[200]};
      cursor: not-allowed;
    }
    svg {
      cursor: not-allowed;
    }
  }
`;

const TopActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
  border-bottom: 1px solid ${colors.neutral[300]};
`;

const BottomActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) =>
    props.isDragging &&
    `
    background-color: ${colors.primary[100]};
    border-color: ${colors.primary[500]};
  `}
  ${(props) =>
    props.isInvalidFile &&
    `
    background-color: ${colors.auxiliary.red[100]};
    border-color: ${colors.auxiliary.red[500]};
  `}
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem 0.75rem;
  ${applyTypography(typography.body.regular)}
  background-color: transparent;
  color: ${colors.text.dark};
  border: none;
  outline: none;
  resize: none; /* prevents manual drag-resizing */
  overflow: hidden; /* hide scrollbar to enable auto-expanding */
  line-height: 1.5;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  &::placeholder {
    color: ${colors.neutral[600]};
  }
  &:focus {
    outline: none;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.text.dark};
`;

const ErrorMessage = styled.span`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: ${colors.auxiliary.red[500]};
`;

/** COMPONENT **/
const DescriptionInput = React.forwardRef(
  (
    {
      label,
      id,
      placeholder,
      icon,
      topActionRow,
      bottomActionRow,
      error,
      disabled,
      className,
      value,
      onChange,
      handleEnter,
      handleDragOver,
      handleDragLeave,
      handleDrop,
      isDragging,
      selectedCards,
      isInvalidFile,
      sourceCards,
      ...props
    },
    ref
  ) => {
    const textAreaRef = useRef(null);

    useEffect(() => {
      if (textAreaRef.current) {
        // Reset height to auto to shrink if necessary
        textAreaRef.current.style.height = "auto";
        // Set height based on scrollHeight
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
    }, [value]);

    const handleChange = (e) => {
      if (onChange) onChange(e);
    };

    // Handle Enter key press
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && handleEnter) {
        e.preventDefault();
        handleEnter();
        // clear the input field
      }
    };

    return (
      <Container className={className}>
        {label && <LabelWrapper htmlFor={id}>{label}</LabelWrapper>}
        <Wrapper
          className={`${error ? "error" : ""} ${disabled ? "disabled" : ""}`}
        >
          {topActionRow && <TopActionRow>{topActionRow}</TopActionRow>}
          <InputContainer
            isDragging={isDragging}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            isInvalidFile={isInvalidFile}
          >
            {sourceCards && sourceCards}
            <StyledTextArea
              id={id}
              ref={textAreaRef}
              placeholder={placeholder}
              disabled={disabled}
              value={value}
              onChange={handleChange}
              onKeyPress={handleKeyPress} // Add handleKeyPress here
              {...props}
            />
            {icon && (
              <IconWrapper>
                <Icon name={icon} size={20} />
              </IconWrapper>
            )}
          </InputContainer>
          {bottomActionRow && (
            <BottomActionRow>{bottomActionRow}</BottomActionRow>
          )}
        </Wrapper>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Container>
    );
  }
);

DescriptionInput.displayName = "DescriptionInput";

DescriptionInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  topActionRow: PropTypes.node,
  bottomActionRow: PropTypes.node,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default DescriptionInput;
