import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  SG_GET_EMPLOYEE_FILE_UPLOAD,
  SG_ADD_EMPLOYEE_FILE_UPLOAD,
  SG_FETCH_EMPLOYEE_FILE_UPLOAD,
  SG_DELETE_EMPLOYEE_FILE_UPLOAD,
  CLEAR_SELECTED_EMPLOYEE_FILE_UPLOAD,
  CLEAR_ADDED_FILE,
} from "constants/actions";
import { useToasts } from "react-toast-notifications";

// Context and Custom Hook
const FileManagementContext = createContext();

export const useFileManagement = () => useContext(FileManagementContext);

// Provider Component
export const FileManagementProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  // State Variables
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pending, setPending] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileSizeInterval, setFileSizeInterval] = useState(null);
  const [pendingUpload, setPendingUpload] = useState(false);
  const [startPolling, setStartPolling] = useState(false);
  // Refs for intervals
  const pollingIntervalRef = useRef(null);
  const progressIntervalRef = useRef(null);

  // Redux Selectors
  const { get_employee_fileupload, get_auth, addedFile, fetchedFile } =
    useSelector(
      (state) => ({
        get_employee_fileupload: state.employee_fileupload,
        get_auth: state.auth,
        uploadedFile: state.employee_fileupload.created,
        addedFile: state.employee_fileupload.added,
        fetchedFile: state.employee_fileupload.selectedFile,
      }),
      shallowEqual
    );

  // Fetch initial data or update files when `get_employee_fileupload` changes
  useEffect(() => {
    setPending(get_employee_fileupload?.pending);
    const results = get_employee_fileupload?.employee_fileuploads?.results;
    setFiles(results || []);
    const totalResults =
      get_employee_fileupload?.employee_fileuploads?.count || 0;
    const pageSize =
      get_employee_fileupload?.employee_fileuploads?.page_size || 10;
    setTotalPages(Math.ceil(totalResults / pageSize) || 1);
  }, [get_employee_fileupload]);

  useEffect(() => {
    if (addedFile && !startPolling) {
      setStartPolling(true);
      setFiles((prev) => [...prev, addedFile]); // Add the file to the list immediately

      // Polling logic
      pollingIntervalRef.current = setInterval(() => {
        dispatch({
          type: SG_FETCH_EMPLOYEE_FILE_UPLOAD,
          payload: { id: addedFile.id },
        });
      }, 2000); // Poll every second
    }

    return () => {
      // Cleanup interval when component unmounts or polling stops
      if (
        pollingIntervalRef.current &&
        fetchedFile &&
        fetchedFile?.id === addedFile?.id &&
        fetchedFile.status === 20
      ) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
        setStartPolling(false);
        dispatch({
          type: CLEAR_ADDED_FILE,
        });
      }
    };
  }, [addedFile, startPolling, dispatch]);

  useEffect(() => {
    if (fetchedFile?.id === addedFile?.id && fetchedFile?.status === 20) {
      // Stop polling
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }

      setStartPolling(false);
      setPendingUpload(false);
    }
  }, [fetchedFile, addedFile]);

  useEffect(() => {
    if (pendingUpload) {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 5; // increase in 5% increments, adjust as desired
        if (progress >= 100) {
          progress = 100;
          clearInterval(interval);
        }
        setUploadProgress(progress);
      }, fileSizeInterval || 300); // update every 300ms, adjust timing as desired
      return () => {
        clearInterval(interval);
        setUploadProgress(0);
      };
    } else {
      // Reset progress if not uploading
      setUploadProgress(0);
    }
  }, [pendingUpload]);

  useEffect(() => {
    if (fileSizeInterval && !pendingUpload) {
      addToast("File uploaded successfully!", { appearance: "success" });
    }
  }, [fileSizeInterval, pendingUpload, addToast]);

  // Fetch files on page load or when page changes
  useEffect(() => {
    dispatch({
      type: SG_GET_EMPLOYEE_FILE_UPLOAD,
      payload: `page=${currentPage}`,
    });
  }, [dispatch, currentPage]);

  // Handle file selection
  useEffect(() => {
    if (get_employee_fileupload.selectedFile) {
      setSelectedFile(get_employee_fileupload.selectedFile);
    }
  }, [get_employee_fileupload.selectedFile]);

  // Actions
  const fetchFiles = (page = 1) => {
    setCurrentPage(page);
    dispatch({
      type: SG_GET_EMPLOYEE_FILE_UPLOAD,
      payload: `page=${page}`,
    });
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("employee", get_auth?.employee_id);
    formData.append("name", file.name);
    formData.append(
      "file_type",
      {
        "application/pdf": "pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          "docx",
        "application/msword": "doc",
      }[file.type] || "unknown"
    );
    formData.append("file_size", file.size);

    const fileSizeKB = file.size / 1024;
    const totalTimeMs = 0.66 * fileSizeKB;
    setFileSizeInterval(totalTimeMs);

    dispatch({ type: SG_ADD_EMPLOYEE_FILE_UPLOAD, payload: formData });
  };

  const deleteFile = (id) => {
    setFiles((prev) => prev.filter((file) => file.id !== id));
    dispatch({ type: SG_DELETE_EMPLOYEE_FILE_UPLOAD, payload: { id } });
  };

  const fetchFileDetails = (id) => {
    dispatch({ type: SG_FETCH_EMPLOYEE_FILE_UPLOAD, payload: { id } });
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
    dispatch({ type: CLEAR_SELECTED_EMPLOYEE_FILE_UPLOAD });
  };

  return (
    <FileManagementContext.Provider
      value={{
        files,
        selectedFile,
        pending,
        currentPage,
        totalPages,
        fetchFiles,
        uploadFile,
        deleteFile,
        fetchFileDetails,
        clearSelectedFile,
        setCurrentPage,
        pendingUpload,
        uploadProgress,
        addedFile,
        uploadInProgress: startPolling,
      }}
    >
      {children}
    </FileManagementContext.Provider>
  );
};

FileManagementProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
