import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Icon from "new_components/atoms/Icon";
import colors from "styles/colors"; // Assuming you are using your colors file

// Wrapper for the Icon with Background
const IconBackground = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size + 16}px; /* Add padding around the icon */
  height: ${({ size }) => size + 16}px; /* Add padding around the icon */
  background-color: ${({ bgColor }) => bgColor || colors.primary[50]};
  border-radius: 8px; /* Rounded corners */
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  hidden: ${({ hidden }) => hidden};
`;

// IconWithBackground Component
const IconWithBackground = ({
  name,
  size = 24,
  color,
  bgColor,
  onClick,
  hidden = false,
  ...props
}) => {
  return (
    <IconBackground
      hidden={hidden}
      size={size}
      bgColor={bgColor}
      onClick={onClick}
    >
      <Icon
        name={name}
        size={size}
        {...props}
        fill={color || colors.primary[500]}
        showPointer={!!onClick}
        style={{
          pointerEvents: onClick ? "auto" : "none",
          cursor: onClick ? "pointer" : "default",
        }}
      />
    </IconBackground>
  );
};

IconWithBackground.propTypes = {
  name: PropTypes.string.isRequired, // Name of the icon
  size: PropTypes.number, // Size of the icon
  color: PropTypes.string, // Color of the icon
  bgColor: PropTypes.string, // Background color
  onClick: PropTypes.func, // Click event handler
};

export default IconWithBackground;
