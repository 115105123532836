import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import BaseTemplate from "./BaseTemplate"; // Import your BaseTemplate
import Icon from "new_components/atoms/Icon";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import colors from "styles/colors";
// Using semantic tooltip for these buttons due to complexity.
import { Popup } from "semantic-ui-react";

const LayoutWrapper = styled.div`
  display: flex;
  height: calc(100vh - 40px);
  overflow: hidden;
  position: relative;
`;

/**
 * Absolutely positioned sidebar that slides in/out.
 * We hide the border when closed.
 */
const SidebarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  background-color: #fff;
  border-right: ${({ isOpen }) => (isOpen ? "1px solid #ddd" : "none")};
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translateX(${({ isOpen }) => (isOpen ? "0" : "-100%")});
  transition: transform 0.3s ease, border-right 0.2s ease;
  padding-left: 5px;
  padding-right: 10px;

  &:hover {
    overflow-y: auto; /* Show scroll on hover */
  }

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

/**
 * Toggle button in the top-left corner.
 * Rotates the icon to show open/close state.
 */
const ToggleButton = styled.div`
  position: absolute;
  top: 16px;
  left: 12px;
  background-color: #fff;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: transform 0.3s ease, background-color 0.2s ease;
  // add background on hover
  &:hover {
    background-color: #ddd;
    border: 1px solid #ddd;
  }
`;

// Position NewChatButton next to the ToggleButton,
// Adjust the position as needed and translate when sidebar is open
const NewChatButton = styled.div`
  position: absolute;
  top: 16px;
  left: ${({ isOpen }) => (isOpen ? "205px" : "60px")};
  background-color: #fff;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1000;

  transition: left 0.3s ease, background-color 0.2s ease;

  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #ddd;
    border: 1px solid #ddd;
  }
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubmenuSection = styled.div`
  height: 100vh;
  border-radius: 4px;
  padding: 12px;
`;

/**
 * The main content slides horizontally to the right
 * (by 350px) when the sidebar is open. This prevents text
 * reflow by effectively shifting the entire container.
 */
const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  /* Slide content to the right if sidebar is open */
  transform: translateX(${({ isOpen }) => (isOpen ? "150px" : "0")});
  transition: transform 0.3s ease;
`;

const ChatScrollArea = styled.div`
  flex: ${({ chatStarted }) => (chatStarted ? "1" : "none")};
  overflow-y: auto;
  padding: 16px;

  /* Example custom scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

// Original ChatContent: shown if chatStarted
const ChatContent = styled.div`
  display: ${({ chatStarted }) => (chatStarted ? "flex" : "none")};
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const ChatHeading = styled.div`
  display: ${({ chatStarted }) => (chatStarted ? "none" : "flex")};
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

/**
 * If you *also* want your bottom InputBox to shift over
 * when the sidebar is open, you can keep the logic
 * for left offset. Because the entire content container
 * is already shifting, you may not need it — but
 * it's included here if you want an *additional* offset
 * for the input.
 */
const InputBox = styled.div`
  position: ${({ chatStarted }) => (chatStarted ? "fixed" : "relative")};
  bottom: ${({ chatStarted }) => (chatStarted ? "40px" : "auto")};
  left: ${({ chatStarted, sidebarOpen }) =>
    chatStarted ? (sidebarOpen ? "350px" : "60px") : "auto"};
  width: ${({ chatStarted }) => (chatStarted ? "calc(100% - 60px)" : "100%")};
  transition: left 0.3s ease;
  display: flex;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
`;

const ChatTemplate = ({
  heading,
  pageTitle,
  subtitle,
  scrollable,
  chatHeading,
  chatContent,
  chatStarted,
  chatComponent,
  submenuData, // { sectionOne: JSX, sectionTwo: JSX }
  activeRoom,
  startNewChat,
  children,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    if (chatStarted) {
      setIsSidebarOpen(false);
    }
  }, [activeRoom, chatStarted]);

  return (
    <BaseTemplate
      heading={!chatStarted && heading}
      pageTitle={!chatStarted && pageTitle}
      subtitle={!chatStarted && subtitle}
      scrollable={chatStarted ? true : scrollable}
    >
      <LayoutWrapper>
        {/* Toggle button always visible in top-left */}
        <Popup
          content="Toggle sidebar"
          inverted
          position="bottom left"
          trigger={
            <ToggleButton onClick={toggleSidebar} isOpen={isSidebarOpen}>
              <Icon
                name={ICON_SET.dashboard}
                style={{ cursor: "pointer" }}
                color={colors.neutral[500]}
              />
            </ToggleButton>
          }
        />

        <Popup
          content="New Chat"
          inverted
          position="bottom left"
          trigger={
            <NewChatButton
              isOpen={isSidebarOpen}
              onClick={() => startNewChat()}
            >
              <Icon
                name={ICON_SET.pencil}
                size={20}
                style={{ cursor: "pointer" }}
              />
            </NewChatButton>
          }
        />

        {/* SIDEBAR */}
        <SidebarWrapper isOpen={isSidebarOpen}>
          <SidebarContent>
            <SubmenuSection>
              {submenuData?.sectionOne /* Render first section */}
            </SubmenuSection>
          </SidebarContent>
        </SidebarWrapper>

        {/* MAIN CHAT CONTENT shifts right when sidebar is open */}
        <ContentWrapper isOpen={isSidebarOpen}>
          <ChatScrollArea chatStarted={chatStarted}>
            <ChatHeading chatStarted={chatStarted}>
              {chatHeading && chatHeading}
            </ChatHeading>
            <ChatContent isOpen={isSidebarOpen} chatStarted={chatStarted}>
              {chatContent && chatContent}
            </ChatContent>
          </ChatScrollArea>

          {/* Bottom input area */}
          <InputBox chatStarted={chatStarted} sidebarOpen={isSidebarOpen}>
            {chatComponent && chatComponent}
          </InputBox>
        </ContentWrapper>
      </LayoutWrapper>

      {children}
    </BaseTemplate>
  );
};

ChatTemplate.propTypes = {
  heading: PropTypes.string,
  pageTitle: PropTypes.string,
  subtitle: PropTypes.string,
  onSend: PropTypes.func,
  scrollable: PropTypes.bool,
  chatHeading: PropTypes.node,
  chatContent: PropTypes.node,
  chatStarted: PropTypes.bool,
  chatComponent: PropTypes.node,
  submenuData: PropTypes.shape({
    sectionOne: PropTypes.node,
    sectionTwo: PropTypes.node,
  }),
};

ChatTemplate.defaultProps = {
  scrollable: false,
};

export default ChatTemplate;
