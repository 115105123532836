import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Components
import Text from "new_components/atoms/Text";
import ImageButton from "../ImageButton";
import IconWithBackground from "../IconWithBackground";
import ProgressDial from "new_components/molecules/ProgressDial";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";

// Styled Components
const Container = styled.div`
  display: flex;
  gap: 16px;
  margin: 8px;
  padding-bottom: 8px; /* Ensure shadows are not clipped */
  overflow-x: auto;
  scroll-behavior: smooth; /* Optional: Smooth scrolling */

  /* Hide scrollbar by default but show it when scrolling is possible */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: ${colors.primary[300]} ${colors.background.lightBlue}; /* For Firefox */

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.primary[300]};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${colors.background.lightBlue};
  }
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${colors.background.lightBlue};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 150px;
  max-width: 225px;
  flex-shrink: 0; /* Prevent cards from shrinking when scrolling */
  opacity: ${(props) => (props.uploadPending ? 0.5 : 1)};
`;

const CardColumn = styled.div`
  display: flex;
  gap: 8px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

// Component
const DataSourceCard = ({
  dataSourceConfig = [],
  selectedCards = [],
  uploadPending,
}) => {
  const [selectedCardsWithCallbacks, setSelectedCardsWithCallbacks] = useState(
    []
  );
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  useEffect(() => {
    const updatedCards = selectedCards?.map((selectedCard) => {
      const dataSourceConfigItem = dataSourceConfig.find(
        (configItem) => configItem.value === selectedCard.type
      );

      return {
        ...selectedCard,
        removeCb:
          dataSourceConfigItem?.removeCb ||
          (() => console.warn("Callback not provided")),
      };
    });

    setSelectedCardsWithCallbacks(updatedCards);

    // Handle loading spinner logic
    if (uploadPending) {
      const mostRecentFileCard = selectedCards
        .filter((card) => card.type === "file")
        .pop();
      if (mostRecentFileCard) {
        setShowLoadingSpinner(true);
      }
    } else {
      setShowLoadingSpinner(false);
    }
  }, [selectedCards, dataSourceConfig, uploadPending]);

  const getIconType = (type) => {
    switch (type) {
      case "pulse":
        return ICON_SET.aiIcon;
      case "factor":
        return ICON_SET.layers;
      case "outcome":
        return ICON_SET.userIcon;
      case "file":
        return ICON_SET.file;
      default:
        return ICON_SET.data;
    }
  };

  // If the title is greater than 20 characters, truncate it and add ellipsis
  const truncateTitle = (title) =>
    title?.length > 20 ? `${title?.substring(0, 20)}...` : title;

  return (
    <Container>
      {selectedCardsWithCallbacks.map((selectedCard, index) => {
        const { type, id, removeCb, title } = selectedCard;
        const iconType = getIconType(type);
        // capitalize first letter of type.
        const capitalizedType =
          (type?.length > 0 && type.charAt(0).toUpperCase() + type.slice(1)) ||
          type;

        let showSpinner = false;
        if (
          showLoadingSpinner &&
          type === "file" &&
          selectedCards[selectedCards.length - 1].id === id
        ) {
          showSpinner = true;
        }

        return (
          <Card key={index} uploadPending={showSpinner && uploadPending}>
            <CardColumn>
              {showSpinner && uploadPending ? (
                <ProgressDial size="30px" infinite />
              ) : (
                <IconWithBackground size={18} name={iconType} />
              )}
              <TextContainer>
                <Text
                  typographyStyle={typography.smallText.semibold}
                  color={colors.primary[500]}
                >
                  {capitalizedType}
                </Text>
                <Text typographyStyle={typography.smallText.regular}>
                  {truncateTitle(title)}
                </Text>
              </TextContainer>
            </CardColumn>

            <ImageButton
              textStyle
              icon={ICON_SET.clear}
              onClick={() => {
                if (type === "file" || type === "pulse") {
                  removeCb(id);
                } else {
                  removeCb(selectedCard);
                }
              }}
            />
          </Card>
        );
      })}
    </Container>
  );
};

// Prop Types
DataSourceCard.propTypes = {
  dataSourceConfig: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      callback: PropTypes.func,
    })
  ),
  selectedCards: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
};

export default DataSourceCard;
